import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../pages/home.vue'),
  },
  {
    path: '/sportsbook',
    component: () => import('../pages/sportsbook.vue'),  
  },
  {
    path: '/reset-pass',
    component: () => import('../pages/reset-pass.vue'),  
  },
  {
    path: '/casino',
    component: () => import('../pages/casino.vue'),  
  },
  {
    path: '/racebook',
    component: () => import('../pages/racebook.vue'),  
  },
  {
    path: '/live-betting',
    component: () => import('../pages/live-betting.vue'),  
  },
  {
    path: '/house-rules',
    component: () => import('../pages/house-rules.vue'),  
  },
  {
    path: '/sports-rules',
    component: () => import('../pages/sports-rules.vue'),  
  },
  {
    path: '/props-builder',
    component: () => import('../pages/props-builder.vue'),  
  },
  {
    path: '/responsible-gaming',
    component: () => import('../pages/responsible-gaming.vue'),  
  },
  {
    path: '/casino-rules',
    component: () => import('../pages/casino-rules.vue'),  
  },
  {
    path: '/game-rules',
    component: () => import('../pages/game-rules.vue'),  
  },
  {
    path: '/terms-conditions',
    component: () => import('../pages/terms-conditions.vue'),  
  },
  {
    path: '/politics-privacity',
    component: () => import('../pages/politics-privacity.vue'),  
  },
  
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
})
export default router