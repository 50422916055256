<template>
  <footer class="footer">
    <div class="footer-content">
      <agent-button/>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routesColumn1" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routesColumn2" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
    </div>
    <div class="footer-content__rights">
      <p><a href="https://pick2score.com/">Pick2Score.com</a> © Derechos Reservados</p>
    </div>
    <mobile-menu/>
  </footer>
</template>


<script>
  import AgentButton from "@/components/agent-button.vue"
  import MobileMenu from '@/components/mobile-menu.vue'

  export default {
    name: "FooterComponent",
    components: {
      AgentButton,
      MobileMenu
    },
    setup() {
      return {
        routesColumn1: [
          {
            name       :  'Política de privacidad',
            route      :  'politics-privacity'
          },
          {
            name       :  'TÉRMINOS Y CONDICIONES',
            route      :  'terms-conditions'
          },
          {
            name       :  'Juego Responsable',
            route      :  'game-rules'
          },
        ],
        routesColumn2: [
          {
            name       :  'Reglas de la Casa',
            route      :  'house-rules'
          },
          {
            name       :  'Reglas de Deportes',
            route      :  'sports-rules'
          },
          {
            name       :  'Reglas de Casino',
            route      :  'casino-rules'
          } 
        ]
      }
    },
  };
</script>
